<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Customer case</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm" class="row">
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.weight"
                label="Weight"
                clearable
                outlined
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.length"
                label="Length"
                clearable
                outlined
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.width"
                label="Width"
                clearable
                outlined
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.height"
                label="Height"
                clearable
                outlined
              ></v-text-field>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {},
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      weight: null,
      length: null,
      width: null,
      height: null,
    },
  }),
  methods: {
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = {
        id: this.$store.getters.getItemForAction.id,
        case_code: this.$store.getters.getItemForAction.case_code,
        warehouse_id: this.$store.getters.getSelectedWarehouse,
      };
      ApiService.post("/warehouse_management/customer_cases/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
          this.dialog = false;
        });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        let data = {
          ...this.formData,
          id: this.$store.getters.getItemForAction.id,
          case_code: this.$store.getters.getItemForAction.case_code,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        };
        ApiService.post("/warehouse_management/customer_cases/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Customer case data has been updated`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        this.formData = this.serverData.customer_case;
      } else {
        this.formData = {
          weight: null,
          length: null,
          width: null,
          height: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
  },
};
</script>
