<template>
  <div class="mt-6">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      show-select
      :items="items"
      item-key="id"
      :server-items-length="items.length"
      :headers="headers"
      :items-per-page="itemPerPage"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            v-model="allSelected"
            name="select-all"
            :value="true"
            class="form-check-input"
            type="checkbox"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            name="single-select"
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div>
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.update"
                :class="
                  !item.extra_data.detail.actions.update
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="editItem(item)"
                text
                >Edit</v-btn
              >
            </div>

            <div>
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.print_barcode"
                :class="
                  !item.extra_data.detail.actions.print_barcode
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => printCaseCode(item)"
                text
                >Print Barcode</v-btn
              >
            </div>

            <div>
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.close_case"
                :class="
                  !item.extra_data.detail.actions.close_case
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => closeCase(item)"
                text
                >Close case</v-btn
              >
            </div>

            <div>
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.force_close_case"
                :class="
                  !item.extra_data.detail.actions.force_close_case
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => forceCloseCase(item)"
                text
                >Force close case</v-btn
              >
            </div>

            <div>
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.delete"
                :class="
                  !item.extra_data.detail.actions.delete
                    ? 'text-muted'
                    : 'text-danger'
                "
                @click="() => deleteCase(item)"
                text
                ><span>Delete</span></v-btn
              >
            </div>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <Pagination
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoader"
      ref="editModal"
    ></EditItem>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import EditItem from "@/own/components/warehouseManagement/customerCases/EditItem";
import Pagination from "@/own/components/pagination/Pagination";
import {
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/customerCases.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";

export default {
  name: "DataTable",
  props: {
    updateTableContent: {
      type: Function,
      required: true,
    },
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    caseCode: {
      required: true,
    },
  },
  components: { Pagination, ComponentSelector, EditItem },
  data: () => ({
    selected: [],
    allSelected: false,
  }),
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    editItem(item) {
      // this.$refs.editModal.$props.itemForAction = item;
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$nextTick(() => {
        this.$refs.editModal.toggleModal();
      });
    },
    printCaseCode(item) {
      this.pageLoader(true);
      ApiService.post("/warehouse_management/customer_cases/print_case_code", {
        case_code: this.caseCode,
        warehouse_id: this.$store.getters.getSelectedWarehouse,
        id: item.id,
      })
        .then((res) => {
          if (!res.data.path) {
            return;
          }
          const link = document.createElement("a");
          link.href = res.data.path;
          link.target = "_blank";
          link.click();

          // this.$nextTick(() => {
          //   SwalService.successMessage({
          //     html: "Customer case is printed successfully!",
          //   });
          // });
        })
        .catch()
        .finally(() => {
          this.pageLoader(false);
        });
    },
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },

    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length > this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    refresher() {
      this.$store.dispatch(UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
      this.selected = [];
    },
    closeCase(item) {
      SwalService.warningConditionMessage(
        {
          title: `Are you sure?`,
          html: `You can't revert it back`,
          confirmButtonText: `Close case`,
        },
        () => {
          this.pageLoader(true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            id: item.id,
            case_code: item.case_code,
          };

          ApiService.post(
            "/warehouse_management/customer_cases/close_case",
            payload
          )
            .then(() => {
              SwalService.successMessage({
                title: "Sucess",
                html: "Case closed successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
            })
            .finally(() => {
              this.pageLoader(false);
            });
        }
      );
    },
    forceCloseCase(item) {
      SwalService.warningConditionMessage(
        {
          title: `Are you sure?`,
          html: `You can't revert it back`,
          confirmButtonText: `Force close`,
        },
        () => {
          this.pageLoader(true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            id: item.id,
            case_code: item.case_code,
          };

          ApiService.post(
            "/warehouse_management/customer_cases/force_close_case",
            payload
          )
            .then(() => {
              SwalService.successMessage({
                title: "Sucess",
                html: "Case closed successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
            })
            .finally(() => {
              this.pageLoader(false);
            });
        }
      );
    },
    deleteCase(item) {
      SwalService.warningConditionMessage(
        {
          title: `Are you sure?`,
          html: `You can't revert it back`,
          confirmButtonText: `Delete`,
        },
        () => {
          this.pageLoader(true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            id: item.id,
            case_code: item.case_code,
          };

          ApiService.post(
            "/warehouse_management/customer_cases/destroy",
            payload
          )
            .then(() => {
              SwalService.successMessage({
                title: "Sucess",
                html: "Case closed successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
            })
            .finally(() => {
              this.pageLoader(false);
            });
        }
      );
    },
  },
  computed: {
    table_permissions: function () {
      if (this.$store.getters.getCUSTOMERCASESTablePermissions) {
        return this.$store.getters.getCUSTOMERCASESTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getCUSTOMERCASESTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getCUSTOMERCASESTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getCUSTOMERCASESTableData?.total;
    },
    pageCount: function () {
      return this.$store.getters.getCUSTOMERCASESTableData?.last_page;
    },

    items: function () {
      let items = [];
      if (this.$store.getters.getCUSTOMERCASESTableData) {
        items = this.$store.getters.getCUSTOMERCASESTableData?.data;
      }
      return items;
    },
    itemPerPage: function () {
      return this.$store.getters.getCUSTOMERCASESTableState.per_page;
    },

    headers: function () {
      return this.$store.getters.getCUSTOMERCASESTableHeaders;
    },
  },
};
</script>
